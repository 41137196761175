<template>
  <div id="app">
    <div
      class="menu-btn p"
      :class="{ active: toggle }"
      @click="toggle = !toggle"
    >
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
    <header class="p" v-if="toggle" @click.self="toggle = false">
      <locale-switcher />
      <navigation />
    </header>
    <div class="crosshair"></div>
    <logo />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
const LocaleSwitcher = () =>
  import(
    /* webpackChunkName: "LocaleSwitcher" */ "@/components/LocaleSwitcher.vue"
  );
const Logo = () =>
  import(/* webpackChunkName: "Logo" */ "@/components/Logo.vue");
const Navigation = () =>
  import(/* webpackChunkName: "Navigation" */ "@/components/Navigation.vue");
export default {
  name: "App",
  metaInfo() {
    return {
      titleTemplate: "aha 2023",
    };
  },
  data() {
    return {
      // the true/false variable to show and hide the menu
      toggle: false,
    };
  },
  components: {
    Navigation,
    Logo,
    LocaleSwitcher,
  },
  watch: {
    $route(to, from) {
      // console.log(to, from);
      if(to.params.lang !== from.params.lang) {
        this.$store.dispatch("loadSite", this.$i18n.locale);
      }
      if(to.hash) {
        this.toggle = false;
      }
    },
  },
  computed: mapState(["site"]),
  created() {
    // this.$store.dispatch("loadSite", this.$i18n.locale);
    window.addEventListener("scroll", this.inViewport);
  },
  methods: {
    inViewport() {
      if(window.innerWidth > 768) {
        let friday = document.getElementById("friday");
        let saturday = document.getElementById("saturday");
        let partner = document.getElementById("partner");
        let hW = window.innerHeight / 2;
        let center = window.scrollY + hW;
        if (friday &&
          center > friday.offsetTop - window.innerHeight / 2 &&
          center < saturday.offsetTop + saturday.clientHeight + window.innerHeight / 2
        ) {
          this.$root.$emit("turnoff");
        } 
        else if (partner && 
          center > partner.offsetTop &&
          center < partner.offsetTop + partner.clientHeight
        ) {
          this.$root.$emit("turnoff");
        } else {
          this.$root.$emit("turnon");
        }
      }
      
    },
  },
};
</script>

<style lang="scss">
@import "node_modules/reset-css/sass/_reset";
@import "@/assets/fonts/PlainMedium-Regular";
@import "@/assets/css/defaults";
@import "@/assets/css/typography";
@import "@/assets/css/transitions";
#app {
  min-height: 100vh;
}
main {
  width:100%;
  // width: 50%;
  // margin-left: 50%;
  min-height: 100vh;
}

.menu-btn {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 700;
  width: calc(4 * var(--lh));
  padding-bottom:calc(0.5 * var(--lh));
  transform: translateZ(51vw);
  @media only screen and (max-width: 768px) {
    width: calc(5 * var(--lh));
  }
  cursor: pointer;
  .bar {
    border-top: var(--b) solid var(--color);
    padding-bottom: calc(0.5 * var(--lh));
    @media only screen and (max-width: 768px) {
      padding-bottom: calc(0.75 * var(--lh));
    }
  }
  &.active {
    .bar:nth-of-type(1) {
      transform: rotate(45deg);
      transform-origin: top left;
    }
    .bar:nth-of-type(2) {
      opacity: 0;
    }
    .bar:nth-of-type(3) {
      transform: rotate(-45deg)
      translate(calc(-0.2 * var(--lh)), calc(0.2 * var(--lh)));
      transform-origin: top left;
      @media only screen and (max-width: 768px) {
        transform: rotate(-45deg) translate(calc(-0.3 * var(--lh)), calc(0.3 * var(--lh)));
      }
    }
  }
}

.crosshair {
  display: block;
  width: calc(4 * var(--lh));
  border-bottom: var(--b) solid var(--color);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 10;
  @media only screen and (max-width:768px) {
    display:none;
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index:600;
  background: var(--bg);
  padding-top: calc(3 * var(--lh)) !important;
  transform:translateZ(50vw);
  @media only screen and (max-width:768px) {
    width:100%;
    padding-top: calc(4 * var(--lh)) !important;
  }
}
</style>
